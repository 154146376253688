<footer class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-area-content">
                    <div class="logo-container">
                        <img src="assets/img/seo/docreach-logo.png" alt="logo">
                        <span class="logo-text">Docreach</span>
                    </div>
                    <p>Docreach specializes in comprehensive digital marketing strategies tailored for the healthcare industry. Elevate your hospital's digital presence and acquire patients more effectively.</p>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>

                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Support Center</a></li>
                        <li><a routerLink="/">Popular Campaign</a></li>
                        <li><a routerLink="/">Customer Support</a></li>
                        <li><a routerLink="/">Copyright</a></li>
                        <li><a routerLink="/">Latest News</a></li>
                    </ul>
                </div>
            </div> -->

            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>My Account</h3>

                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Speaker requests</a></li>
                        <li><a routerLink="/">Images and b-roll</a></li>
                        <li><a routerLink="/">Social media directories</a></li>
                        <li><a routerLink="/">Permissions</a></li>
                        <li><a routerLink="/">Press inquiries</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact</h3>

                    <ul class="footer-quick-links address-link">
                        <!-- <li><a href="#"><i class="fa fa-map-marker"></i> 121 King St, VIC 3000, Australia</a></li> -->
                        <li><a href="mailto:support@docreach.in"><i class="fa fa-envelope"></i> support@docreach.in</a></li>
                        <!-- <li><a href="tel:09812323455"><i class="fa fa-phone"></i> +098-123-23455</a></li> -->
                    </ul>

                    <!-- <ul class="social-list">
                        <li><a href="#" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

<div class="sidebar-modal">  
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <i class="fa fa-times"></i>
                        </span>
                    </button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/">
                            <img src="assets/img/logo/logo.png" alt="Logo">
                        </a>
                    </h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Welcome To Ervy</h3>
                        <img src="assets/img/about.jpg" alt="">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolore saepe exercitationem alias unde, blanditiis non error</p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="fa fa-map-marker"></i>
                                Address
                                <span>121 King St, VIC 3000, Australia</span>
                            </li>

                            <li>
                                <i class="fa fa-phone"></i>
                                Email
                                <span>hello@ervy.com</span>
                            </li>

                            <li>
                                <i class="fa fa-envelope"></i>
                                Phone
                                <span>+098-123-23455</span>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>