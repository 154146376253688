<app-navbar-one></app-navbar-one>

<div class="saas-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <span>Exto Saas Agency</span>
                            <h1>The audience is core elements to grow your brand</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolet suspendisse ultrices gravida. </p>
                            
                            <div class="saas-btn">
                                <a routerLink="/pricing" class="saas-btn-one">Start Free Trial <i class="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">
                            <img src="assets/img/saas-banner/mobile.png" alt="image">
                            <img class="banner-tab" src="assets/img/saas-banner/mobile-two.png" alt="image">
                        </div>
                    </div>
                    
                    <div class="circle-img">
                        <img src="assets/img/saas-banner/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape6.png" alt="image"></div>
</div>

<section class="features-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/features/features-img1.png" alt="image">

                    <div class="features-shape">
                        <img src="assets/img/features/shape.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>

                    <div class="features-arrow">
                        <img src="assets/img/features/features-img2.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="features-area-content">
                    <span>Features</span>
                    <h2>The features of the best from extra</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor labore suspendisse ultrices gravida.</p>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Cloud Service</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-ffe7dc">
                                <div class="icon">
                                    <i class="flaticon-lock"></i>
                                </div>
                                <h3>Well Documented</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                            </div>
                        </div>
                    </div>

                    <div class="features-btn">
                        <a routerLink="/about" class="default-btn">Learn More <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="services-area-content">
                    <span>All Services</span>
                    <h3>The simple ways to use other than to the agency</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem voluptas sed magni voluptate, unde atque, quis voluptatum quas quasi nobis consectetur. Recusandae rem repellat nostrum dolore ipsum.</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur commodi dolorum vel placeat quis autem. Consequuntur, sunt nobis.</p>

                    <div class="services-btn">
                        <a routerLink="/services" class="default-btn">View All Services <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-services">
                    <div class="row">
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <img src="assets/img/services/services-img1.png" alt="image">
                                <h3>Business Intelligence</h3>
                            </div>
                        </div>

                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <img src="assets/img/services/services-img2.png" alt="image">
                                <h3>People Plus</h3>
                            </div>
                        </div>

                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <img src="assets/img/services/services-img3.png" alt="image">
                                <h3>Commerce Apps</h3>
                            </div>
                        </div>

                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <img src="assets/img/services/services-img4.png" alt="image">
                                <h3>Workplace</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="video-section pb-100">
    <div class="container">
        <div class="video-content">
            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i></a>
        </div>
    </div>
</div>

<section class="fun-facts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Completed projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3><span class="odometer" data-count="253">00</span><span class="sign-icon">+</span></h3>
                    <p>Customer satisfaction</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3><span class="odometer" data-count="24">00</span><span class="sign-icon">h</span></h3>
                    <p>Expert Support Team</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3><span class="odometer" data-count="78">00</span><span class="sign-icon">k</span></h3>
                    <p>Sales in Count</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="benefit-section pb-70">
    <div class="container">
        <div class="section-title">
            <span>The benefit</span>
            <h2>What you will get those are the need for you</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-diagram"></i>
                    <h3>Fast Growing system</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, quos et. Quibusdam asperiores natus est nisi, enim, laudantium ut deleniti optio aspernatur deserunt.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-money"></i>
                    <h3>Save Money systemically</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, quos et. Quibusdam asperiores natus est nisi, enim, laudantium ut deleniti optio aspernatur deserunt.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-pen"></i>
                    <h3>Use of Design Tools</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, quos et. Quibusdam asperiores natus est nisi, enim, laudantium ut deleniti optio aspernatur deserunt.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-refresh"></i>
                    <h3>Updates System available</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, quos et. Quibusdam asperiores natus est nisi, enim, laudantium ut deleniti optio aspernatur deserunt.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="support-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="support-image">
                    <img src="assets/img/support/support-img1.png" alt="image">

                    <div class="support-shape">
                        <img src="assets/img/support/support-img2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>

                    <div class="support-arrow">
                        <img src="assets/img/support/support-img3.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-area-content">
                    <span>The support</span>
                    <h3>Management is easy when getting support properly</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, temporibus autem! Provident, soluta quod atque libero magnam accusamus quasi inventore enim accusamus quasi laboriosam voluptates laudantium veritatis aliquid labore error molestiae nulla?</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur, impedit debitis! Ipsum ratione dolore sit numquam dicta ex officiis accusantium accusamus iure nihil? Accusamus aspernatur harum provident.</p>

                    <div class="support-btn">
                        <a routerLink="/" class="default-btn">Learn More <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="software-section pb-100">
    <div class="container">
        <div class="software-title">
            <h2>We're a software development Meet our updated screen</h2>
        </div>

        <div class="tab software-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="flaticon-budget"></i> Budget Overview</a></li>
                <li><a href="#"><i class="flaticon-data"></i> Integrations</a></li>
                <li><a href="#"><i class="flaticon-file"></i> View Reports</a></li>
                <li><a href="#"><i class="flaticon-ruler"></i> Create & Adjust</a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="tab-image">
                        <img src="assets/img/software-tab/software-img1.png" alt="image">

                        <div class="tab-shape">
                            <img src="assets/img/software-tab/software-img2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                        </div>

                        <div class="tab-arrow">
                            <img src="assets/img/software-tab/software-img3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="image">
                        </div>

                        <div class="tab-arrow-two">
                            <img src="assets/img/software-tab/software-img3.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s"  alt="image">
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="tab-image">
                        <img src="assets/img/software-tab/software-img1.png" alt="image">

                        <div class="tab-shape">
                            <img src="assets/img/software-tab/software-img2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                        </div>

                        <div class="tab-arrow">
                            <img src="assets/img/software-tab/software-img3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="image">
                        </div>

                        <div class="tab-arrow-two">
                            <img src="assets/img/software-tab/software-img3.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s"  alt="image">
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="tab-image">
                        <img src="assets/img/software-tab/software-img1.png" alt="image">

                        <div class="tab-shape">
                            <img src="assets/img/software-tab/software-img2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                        </div>

                        <div class="tab-arrow">
                            <img src="assets/img/software-tab/software-img3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="image">
                        </div>

                        <div class="tab-arrow-two">
                            <img src="assets/img/software-tab/software-img3.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s"  alt="image">
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="tab-image">
                        <img src="assets/img/software-tab/software-img1.png" alt="image">

                        <div class="tab-shape">
                            <img src="assets/img/software-tab/software-img2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                        </div>

                        <div class="tab-arrow">
                            <img src="assets/img/software-tab/software-img3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="image">
                        </div>

                        <div class="tab-arrow-two">
                            <img src="assets/img/software-tab/software-img3.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s"  alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="pricing-area-content">
                    <span>Pricing plan</span>
                    <h3>Our Pricing is Affordable To all customer</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, temporibus autem! Provident, soluta quod atque libero magnam accusamus quasi inventore enim accusamus quasi laboriosam voluptates laudantium veritatis aliquid labore error molestiae nulla?</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur, impedit debitis! Ipsum ratione dolore sit numquam dicta ex officiis accusantium accusamus iure nihil? Accusamus aspernatur harum provident</p>
                </div>

                <div class="pricing-btn-wrap">
                    <div class="price-btn">
                        <a routerLink="/pricing" class="price-btn-one">Pricing Plan <i class="fa fa-arrow-right"></i></a>
                    </div>

                    <div class="price-btn">
                        <a routerLink="/pricing" class="price-btn-one price-two">Annual  Plan <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Bussines Account</h3>
                    </div>

                    <div class="price">
                        $56/ <span>Monthly</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Drug & Drop builder</li>
                        <li><i class="fa fa-arrow-right"></i> 1009s account ready</li>
                        <li><i class="fa fa-arrow-right"></i> Unlimited Email</li>
                        <li><i class="fa fa-arrow-right"></i> Blog tools</li>
                        <li><i class="fa fa-arrow-right"></i> Ecommerce store</li>
                        <li><i class="fa fa-arrow-right"></i> 40+ Webmaster tools</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Create a Free Account</a>
                    </div>
                </div>   
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box table-price">
                    <div class="pricing-header">
                        <h3>Standard Account</h3>
                    </div>

                    <div class="price">
                        $70/ <span>Monthly</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Drug & Drop builder</li>
                        <li><i class="fa fa-arrow-right"></i> 1009s account ready</li>
                        <li><i class="fa fa-arrow-right"></i> Unlimited Email</li>
                        <li><i class="fa fa-arrow-right"></i> Blog tools</li>
                        <li><i class="fa fa-arrow-right"></i> Ecommerce store</li>
                        <li><i class="fa fa-arrow-right"></i> 40+ Webmaster tools</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Start a Free trial</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="client-image">
                    <img src="assets/img/client/client-img1.png" alt="image">

                    <div class="client-shape">
                        <img src="assets/img/client/client-img2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="client-wrap owl-carousel owl-theme">
                    <div class="client-content-area">
                        <h3>Jonah Dew</h3>
                        <span>Flagstone Company Canada</span>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt aliqua.</p>

                        <div class="client-logo">
                            <img src="assets/img/client/client-img3.png" alt="image">
                        </div>
                    </div>

                    <div class="client-content-area">
                        <h3>Anderson Jonah</h3>
                        <span>Flagstone Company USA</span>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt aliqua.</p>

                        <div class="client-logo">
                            <img src="assets/img/client/client-img3.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-section ptb-100">
    <div class="container-fluid pr-0">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-area-content">
                    <span>Email address</span>
                    <h3>Subscribe our monthly newsletter to get updated</h3>
                    <p>Don’t be afraid your mail is secure it no will be shared anywhere or everywhere.</p>

                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Submit now <i class="fa fa-arrow-right"></i></button>
                    </form>

                    <ul class="social-list">
                        <li class="list-heading">Follow us for update:</li>
                        <li><a href="#" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="subscribe-image">
                    <div class="main-image">
                        <img src="assets/img/subscribe/subscribe-img5.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/subscribe/subscribe-img2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/subscribe/subscribe-img3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/subscribe/subscribe-img4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>

                    <div class="subscribe-mobile-image">
                        <img src="assets/img/subscribe/pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-section pb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#" target="_blank" class="d-block"><img src="assets/img/partnar/partner-img1.png" alt="partner"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block"><img src="assets/img/partnar/partner-img2.png" alt="partner"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block"><img src="assets/img/partnar/partner-img3.png" alt="partner"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block"><img src="assets/img/partnar/partner-img4.png" alt="partner"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block"><img src="assets/img/partnar/partner-img5.png" alt="partner"></a>
            </div>
        </div>
    </div>
</div>

<section class="talk-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="talk-form">
                    <form>
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" placeholder="What's Your Name">
                        </div>

                        <div class="form-group">
                            <input type="email" name="email" class="form-control" placeholder="Your email address here">
                        </div>

                        <div class="form-group">
                            <input type="text" name="subject" class="form-control" placeholder="Subject">
                        </div>

                        <div class="form-group">
                            <textarea name="message" class="form-control" cols="30" rows="6" placeholder="Write your text here"></textarea>
                        </div>

                        <button type="submit" class="btn btn-primary">Submit Now</button>
                    </form>
                </div>

                <div class="talk-image">
                    <div class="talk-shape">
                        <img src="assets/img/talk/talk-img1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>

                    <div class="talk-arrow">
                        <img src="assets/img/talk/talk-img2.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="talk-content-area">
                    <span>Have any queries?</span>
                    <h3>Let’s Talk if you have any talk to us</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.</p>

                    <div class="talk-info">
                        <p>if you need emergency somethings</p>
                        <h3>+09987-3542-8863</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>