<div class="navbar-area-two">
  <div class="exto-responsive-nav">
      <div class="container">
          <div class="exto-responsive-menu">
              <div class="logo">
                  <a routerLink="/">
                    <div class="logo-container">
                        <img src="assets/img/seo/docreach-logo.png" alt="logo">
                        <span class="logo-text">Docreach</span>
                    </div>
                  </a>
              </div>
          </div>
      </div>
  </div>

  <div class="exto-nav">
      <div class="container">
          <nav class="navbar navbar-expand-md navbar-light">
              <a class="navbar-brand" routerLink="/">
                <div class="logo-container">
                    <img src="assets/img/seo/docreach-logo.png" alt="logo">
                    <span class="logo-text">Docreach</span>
                </div>
              </a>
          </nav>
      </div>
  </div>
</div>