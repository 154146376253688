<app-navbar-four></app-navbar-four>

<div class="seo-agency-home-area">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <div class="hero-content">
                          <h1>Leading Digital Marketing Solutions for Hospitals</h1>
                          <p>Docreach specializes in comprehensive digital marketing strategies tailored for the healthcare industry. Elevate your hospital's digital presence and acquire patients more effectively.</p>

                      </div>
                  </div>  

                  <div class="col-lg-12">
                      <div class="seo-agency-image">
                          <img src="assets/img/seo/landing.png" alt="image">
                      </div>
                  </div>  
              </div>
          </div>
      </div>
  </div>

  <div class="seo-shape"><img src="assets/img/seo/shape.png" alt="image"></div>
  <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
  <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
  <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
  <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
  <div class="shape-img5"><img src="assets/img/shape/shape6.png" alt="image"></div>
  <div class="nav-shape"><img src="assets/img/shape/shape18.png" alt="image"></div>
</div>

<section class="visitor-section pb-70">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
              <div class="visitor-title">
                  <h2>Expand Your Hospital's Digital Reach </h2>
              </div>
          </div>

          <div class="col-lg-6 col-md-12">
              <div class="visitor-text">
                  <p>At Docreach, we combine innovative digital marketing techniques with deep healthcare industry knowledge to maximize patient engagement and boost your online visibility.</p>
              </div>
          </div>
      </div>

      <div class="row">
          <div class="col-lg-3 col-sm-6 col-md-6">
              <div class="visitor-content-area">
                  <div class="icon">
                      <i class="flaticon-data"></i>
                  </div>
                  <h3>Accurate ROI Calculation</h3>
                  <p>Utilize precise data analysis to calculate the true ROI of your digital marketing campaigns, correlating efforts with actual patient visits and engagements.</p>

                  <div class="visitor-shape">
                      <img src="assets/img/seo/shape-2.png" alt="image">
                  </div>
              </div>
          </div>

          <div class="col-lg-3 col-sm-6 col-md-6">
              <div class="visitor-content-area bg-00a86f">
                  <div class="icon">
                      <i class="flaticon-goal"></i>
                  </div>
                  <h3>Free NeftX HMS Software</h3>
                  <p>Benefit from our exclusive offer of free NeftX HMS software with our digital marketing services, integrating hospital management seamlessly with online strategy.</p>

                  <div class="visitor-shape">
                      <img src="assets/img/seo/shape-2.png" alt="image">
                  </div>
              </div>
          </div>

          <div class="col-lg-3 col-sm-6 col-md-6">
              <div class="visitor-content-area bg-302353">
                  <div class="icon">
                      <i class="flaticon-modules"></i>
                  </div>
                  <h3>Localized Patient Search Integration</h3>
                  <p>Harness deep insights into patient search behavior by locality. Our media promotion strategies are fine-tuned to target and attract the local patient community effectively.</p>

                  <div class="visitor-shape">
                      <img src="assets/img/seo/shape-2.png" alt="image">
                  </div>
              </div>
          </div>

          <div class="col-lg-3 col-sm-6 col-md-6">
              <div class="visitor-content-area bg-c53a17">
                  <div class="icon">
                      <i class="flaticon-cyber-security"></i>
                  </div>
                  <h3>Advanced Dashboards & Rewards</h3>
                  <p>Manage your digital marketing tools efficiently with our intuitive dashboards. Enjoy the added benefit of a unique rewards system, incentivizing active engagement with NeftX's platform.</p>

                  <div class="visitor-shape">
                      <img src="assets/img/seo/shape-2.png" alt="image">
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="specialty-section pb-100">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-6">
              <div class="specialty">
                  <img src="assets/img/seo/specialty.png" alt="Docreach Speciality">
              </div>
          </div>

          <div class="col-lg-6">
              <div class="specialty-content-area">
                  <span>Our Specialty</span>
                  <h3>Digital Marketing Mastery</h3>
                  <p>Our team at Docreach is dedicated to enhancing your hospital's digital footprint through targeted marketing campaigns that resonate with your audience and drive results.</p>

                  <ul class="specialty-list">
                      <li><i class="fa fa-arrow-right"></i> Seamless Integration</li>
                      <li><i class="fa fa-arrow-right"></i> Complete Automation</li>
                      <li><i class="fa fa-arrow-right"></i> Customer Experience</li>
                      <li><i class="fa fa-arrow-right"></i> Non-Obtrusive Design</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="department-section pb-100">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
              <div class="department-area-content">
                  <span>Our Approach</span>
                  <h3>Creative and Strategic Digital Marketing</h3>
                  <p>Our team offers innovative and structured digital marketing solutions, ensuring your hospital achieves a distinguished presence in the digital landscape.</p>

                  <ul class="department-list">
                      <li><i class="fa fa-arrow-right"></i> End-to-End Tracking - One hospital, One software</li>
                      <li><i class="fa fa-arrow-right"></i> Google Trend Analysis based keyword generation</li>
                      <li><i class="fa fa-arrow-right"></i> Automated Website Analysis</li>
                  </ul>
              </div>
          </div>

          <div class="col-lg-6 col-md-12">
              <div class="department">
                  <img src="assets/img/seo/department.png" alt="image">
              </div>
          </div>
      </div>
  </div>
</section>

<section class="success-section ptb-100">
  <div class="container">
      <div class="success-title">
          <h3>Proven Success in Hospital Digital Marketing</h3>
      </div>

      <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="success-area-content">
                  <i class="flaticon-industry"></i>
                  <h3>Longstanding Expertise</h3>
                  <span>Pioneering strategies that set industry standards.</span>
              </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="success-area-content">
                  <i class="flaticon-group"></i>
                  <h3>Happy Clients</h3>
                  <span>50+ Happy Clients</span>
              </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="success-area-content">
                  <i class="flaticon-promotion"></i>
                  <h3>Quality Deliverables</h3>
                  <span>Highest standards of quality assured!</span>
              </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="success-area-content">
                  <i class="flaticon-coffee-cup"></i>
                  <h3>Customized Strategies</h3>
                  <span>Tailored Marketing Strategies!</span>
              </div>
          </div>
      </div>
  </div>
</section>

<div class="partnar-three-section pt-100">
  <div class="container">
      <div class="partner-slider owl-carousel owl-theme">
          <div class="partner-item" target="_blank" class="d-block">
              <a href="https://snr.neftx.com/" target="_blank">
                  <img src="https://storage.googleapis.com/neftx_uploads/b08b2975-20f2-4bac-bbee-d716ad2410fe" alt="Snr diagnostic center">
              </a>
          </div>

          <div class="partner-item" target="_blank" class="d-block">
                <a href="https://sripadha.neftx.com/" target="_blank">
                  <img src="https://storage.googleapis.com/neftx_uploads/123a2aed-3c61-4e41-a9cf-7b7f5cd5f18a" alt="Sripadha dental clinic">
              </a>
          </div>

          <div class="partner-item" target="_blank" class="d-block">
              <a href="https://globaldent.neftx.com/" target="_blank" class="d-block">
                  <img src="https://storage.googleapis.com/neftx_uploads/59df5a44-346c-4f72-9e66-f30649d44cc8" alt="Globaldent Dental clinic">
              </a>
          </div>
          
          <div class="partner-item" target="_blank" class="d-block">
              <a href="#">
                  <img src="https://storage.googleapis.com/neftx_uploads/83ab1aec-d06a-4e6d-83d9-d56756f14fc1" alt="Skanda hospitals">
              </a>
          </div>

          <div class="partner-item" target="_blank" class="d-block">
              <a href="#">
                  <img src="https://storage.googleapis.com/neftx_uploads/53775fbf-3960-40c0-8bf2-3719f09dfd1a" alt="Medha hospital">
              </a>
          </div>
      </div>
  </div>
</div>


<section class="increase-section">
  <div class="container">
      <div class="increase-title">
          <span>Elevate Your Hospital's Online Presence</span>
          <h3>Boost your hospital's online profile</h3>
      </div>

      <div class="row">
          <div class="col-lg-6 col-md-6">
              <div class="increase-item">
                  <div class="increase-area-content">
                      <img src="assets/img/icon/icon5.png" alt="image">
                      <h3>Specialty-Based Marketing Solutions</h3>
                      <p>Customized Marketing for Medical Specialties - Tailored strategies that cater to the unique needs and nuances of different medical specialties, ensuring more targeted and effective marketing campaigns.</p>
                  </div>
                  
                  <div class="increase-shape">
                      <img src="assets/img/icon/shape.png" alt="image">
                  </div>
              </div>    
          </div>

          <div class="col-lg-6 col-md-6">
              <div class="increase-item">
                  <div class="increase-area-content">
                      <img src="assets/img/icon/icon6.png" alt="image">
                      <h3>Localized Trend Analysis</h3>
                      <p>Location-Wise Trend Analysis - Advanced analytics to understand local healthcare trends and patient needs, enabling us to craft location-specific marketing solutions that resonate with the target community.</p>
                  </div>

                  <div class="increase-shape">
                      <img src="assets/img/icon/shape.png" alt="image">
                  </div>
              </div>    
          </div>

          <div class="col-lg-6 col-md-6">
              <div class="increase-item">
                  <div class="increase-area-content">
                      <img src="assets/img/icon/icon7.png" alt="image">
                      <h3>Comprehensive Market Research</h3>
                      <p>In-Depth Market Research and Strategy - Utilize thorough research methodologies to understand both the broader market and specific local dynamics, forming the basis of our comprehensive marketing strategies.</p>
                  </div>

                  <div class="increase-shape">
                      <img src="assets/img/icon/shape.png" alt="image">
                  </div>
              </div>    
          </div>

          <div class="col-lg-6 col-md-6">
              <div class="increase-item">
                  <div class="increase-area-content">
                      <img src="assets/img/icon/icon8.png" alt="image">
                      <h3>Geo-Targeted Campaigns</h3>
                      <p>Leverage geo-targeted campaigns to maximize visibility and patient engagement in specific areas, enhancing local reach and relevance for hospitals and clinics.</p>
                  </div>

                  <div class="increase-shape">
                      <img src="assets/img/icon/shape.png" alt="image">
                  </div>
              </div>    
          </div>
      </div>
  </div>

  <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
</section>

<section class="pricing-section ptb-100">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
              <div class="pricing-area-content seo-price-title">
                  <span>pricing</span>
                  <h3>Sign up for an annual plan and get two months of service absolutely free!</h3>
                  <p>Choose from our range of digital marketing packages, each competitively priced to offer value and exceptional results for hospitals.</p>
              </div>
          </div>

          <div class="col-lg-3 col-md-6">
              <div class="single-pricing-box seo-price price-z">
                  <div class="pricing-header">
                      <h3>Digital Presence Package</h3>
                  </div>

                  <div class="price">
                    Rs. 20,000 per month
                  </div>

                  <ul class="pricing-features">
                      <li><i class="fa fa-arrow-right"></i> Three Blogs Per Month</li>
                      <li><i class="fa fa-arrow-right"></i> Social Media Handling</li>
                      <li><i class="fa fa-arrow-right"></i> Website Content Design</li>
                      <li><i class="fa fa-arrow-right"></i> Domain Connection</li>
                      <li><i class="fa fa-arrow-right"></i> Organic SEO</li>
                      <li><i class="fa fa-arrow-right"></i> Daily, Monthly reports</li>
                  </ul>
              </div>   
          </div>

          <div class="col-lg-3 col-md-6">
              <div class="single-pricing-box table-price seo-price">
                  <div class="pricing-header">
                      <h3>Digital Marketing Package</h3>
                  </div>

                  <div class="price">
                    Rs. 25,000 per month + Minimum Rs. 30,000 Ad Spend
                  </div>

                  <ul class="pricing-features">
                      <li><i class="fa fa-arrow-right"></i> Includes All Features of Digital Presence</li>
                      <li><i class="fa fa-arrow-right"></i> Ads Creation & Campaign Management</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</section>


<section class="request-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfXP4ojrmJo3R1PSsZVAKSIweyFi5fYoGsYbBXSTsAEc_KP4Q/viewform?embedded=true" width="480" height="890" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    </div>
</section>


<section class="update-section ptb-100">
  <div class="container">
      <div class="update-title">
          <h3>Get Every Single Update</h3>
      </div>

      <form class="update-form">
          <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required>
          <button type="submit">Subscribe Newsletter</button>
      </form>
  </div>

  <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
  <div class="shape-2"><img src="assets/img/shape/shape20.png" alt="Images"></div>
</section>